var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "confirm-container mx-auto" },
      [
        _c(
          "v-row",
          { staticClass: "text-center", attrs: { "no-gutters": "" } },
          [
            _c("v-col", { attrs: { cols: "12" } }, [
              _c("h1", { staticClass: "my-6" }, [
                _vm._v(_vm._s(_vm.$t("register.completed.title")))
              ]),
              _c("div", { staticClass: "msg-body mb-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("register.completed.text", [_vm.mail])) +
                    " "
                )
              ]),
              _c(
                "p",
                { staticClass: "confirm mt-5 mb-3 font-weight-semibold" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("register.completed.socialConfirm")) +
                      " "
                  )
                ]
              )
            ])
          ],
          1
        ),
        _c(
          "v-row",
          {
            staticClass: "btn-container",
            attrs: { "no-gutters": "", justify: "center" }
          },
          [
            _c(
              "v-btn",
              {
                staticClass: "primary white--text",
                attrs: {
                  large: "",
                  depressed: "",
                  rounded: "",
                  "min-width": "200"
                },
                on: { click: _vm.goToHome }
              },
              [_vm._v(_vm._s(_vm.$t("register.completed.goToHome")))]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }