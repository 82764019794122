<template>
  <v-container>
    <div class="confirm-container mx-auto">
      <v-row no-gutters class="text-center">
        <v-col cols="12">
          <h1 class="my-6">{{ $t("register.completed.title") }}</h1>
          <div class="msg-body mb-3">
            {{ $t("register.completed.text", [mail]) }}
          </div>
          <p class="confirm mt-5 mb-3 font-weight-semibold">
            {{ $t("register.completed.socialConfirm") }}
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="btn-container">
        <v-btn
          large
          depressed
          rounded
          class="primary white--text"
          min-width="200"
          @click="goToHome"
          >{{ $t("register.completed.goToHome") }}</v-btn
        >
      </v-row>
    </div>
  </v-container>
</template>
<style lang="scss">
.confirm-container {
  width: 60%;
  padding: 35px 0 0x;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
  }
  h1 {
    font-size: 50px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 25px;
    }
  }
  .msg-body,
  .confirm,
  .warning-text {
    font-size: 20px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 18px;
    }
  }
  .warning-text {
    color: #f80100;
  }
  .btn-container {
    margin-top: 90px;
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";
export default {
  name: "RegistrationCompleted",

  data() {
    return {
      mail: "",
      socialLogin: null,
      agreement: null
    };
  },
  mixins: [categoryMixins],
  methods: {
    async goToHome() {
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  },
  created() {
    this.mail = this.$route.params.mail;
    this.socialLogin = this.$route.params.socialLogin;
    this.agreement = this.$route.params.agreement;
  }
};
</script>
